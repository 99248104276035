.dashboardAlert {
  min-width: 100%;
  margin: 0 calc(-41.25vw + 50%);
  margin-top: -30px;
  margin-bottom: 20px;
  object-fit: cover;
  border-radius: 0;
  background-color: #bcf4bc;
  border-color: #bcf4bc;

  @media (max-width: 991px) {
    margin: 0 calc(-50vw + 50%);
    margin-top: -30px;
    margin-bottom: 20px;
  }

  button.close {
    color: var(--colorBlue) !important;
  }
}
