.custom-paginator {
  display: flex;
  width: 100%;
  justify-content: space-between;

  .pagination-label {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: #8993a4;
  }

  // Customizing the Paginator to taste
  .react-pagination-js-bootstrap {
    ul {
      box-shadow: unset !important;

      .page {
        border: unset !important;
        color: var(--cardBorderColor) !important;
      }
      .is-active {
        border: unset !important;
        color: var(--colorPurple) !important;
        background-color: var(--colorHoverState) !important;
      }
      .pageElli {
        border: unset !important;
        color: #677684 !important;
        transform: translateY(-8px) !important;
        font-weight: bold !important;

        a {
          font-size: 28px !important;
          color: #677684 !important;
        }
      }
    }
  }
}
