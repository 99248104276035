.sectorDetailsGrid {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 30px;
  margin-top: 20px;

  @media only screen and (max-width: 992px) {
    grid-template-columns: 1fr;
  }
}
