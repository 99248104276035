$sidebar-width: 17.5%;
$sidebar-width-mobile: 100%;
$header-height: 64px;

.authPagesWrapper {
  position: relative;

  .headerSection {
    display: flex;
    justify-content: space-between;
    background-color: var(--colorBackgroundPurple);
    height: $header-height;
    position: fixed;
    left: 0;
    top: 0;
    min-width: 100%;
    max-width: 100%;
    padding: 10px 20px;
    z-index: 100;

    @media only screen and (max-width: 992px) {
      padding: 10px;
    }

    .logo {
      img {
        height: 70%;
        top: 15%;
        position: relative;
        max-width: 100%;

        @media only screen and (max-width: 992px) {
          height: 60%;
          top: 20%;
        }
      }
    }
  }

  .main {
    margin-left: $sidebar-width;
    transition: margin-left 0.75s;
    overflow-y: none;
    background-color: var(--colorWhite);

    @media only screen and (max-width: 992px) {
      margin-left: 0;

      &.noSidebar {
        margin-left: 0%;
      }
    }

    .children {
      overflow: hidden;
      margin: 0;
      background-color: var(--colorWhite);
      position: relative;
      min-height: 100vh;

      .manipulateChildren {
        padding: 30px 30px;
        margin-top: $header-height;
        @media only screen and (max-width: 992px) {
          padding: 5px;
          margin-top: 90px;
        }
      }

      //Toggle Button
      .menuToggleBtn {
        display: none;

        @media only screen and (max-width: 992px) {
          cursor: pointer;
          position: fixed;
          box-sizing: border-box;
          z-index: 100;
          display: block;
          padding: 10px;
          top: 12px;
          right: 0;
        }

        span {
          background: var(--colorWhite);
          display: block;
          width: 30px;
          height: 6px;
          border-radius: 5px;
          margin-bottom: 6px;
          -webkit-transition: all linear;
          transition: all linear;

          @media only screen and (max-width: 992px) {
            height: 4px;
            width: 25px;
            margin-bottom: 4px;
          }
        }
        &.open {
          transition: right ease-in-out 500ms;
          transform: translateY(-4px);
          span {
            &:nth-child(1),
            &:nth-child(3) {
              transform: translate(0px, 13px) rotate(-45deg);
              margin: 0;
            }
            &:nth-child(2) {
              height: 0;
              margin: 0;
            }
            &:nth-child(3) {
              transform: translate(0px, 9px) rotate(45deg);
            }
          }
        }
      }
    }
  }
  .sidebar {
    height: 100%;
    padding-top: 0px !important;
    background-color: #f4f5f6;
    position: fixed !important;
    z-index: 99;
    overflow: auto;
    min-width: $sidebar-width;
    max-width: $sidebar-width;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: all ease-in-out 500ms;

    @media only screen and (max-width: 992px) {
      min-width: $sidebar-width-mobile;
      max-width: $sidebar-width-mobile;
      background-color: var(--colorBackgroundPurple);

      &.hideSidebar {
        display: none;
      }
    }
  }
}
