.actionButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--colorWhite);

  @media only screen and (max-width: 992px) {
    display: none;

    &.open {
      transform: translate(-10px, 25px);
      min-width: 100vw !important;
      margin-bottom: 150px;
      justify-content: center;
      display: flex;
    }
  }

  .notificationBox {
    margin: 0 10px;
    position: relative;
    width: 20px;
    height: 21.5px;
    cursor: pointer;

    img {
      position: relative;
      min-height: 100%;
      max-height: 100%;
      max-width: 100%;
      max-width: 100%;
    }

    .notificationDot {
      position: absolute;
      right: 0px;
      top: 0px;
      background-color: #f08829;
      border-radius: 50%;
      width: 8px;
      height: 8px;
      z-index: 1;
    }
  }
  // Bell Icon Area
  .notificationBox {
    position: relative;
    .dropdownNotificationMenu {
      position: absolute;
      top: 100%;
      right: -90px;
      margin-top: 100%;
      padding: 24px 16px;
      background: #fff;

      .notificationHeader {
        display: flex;
        align-items: flex-start;
        gap: 10px;
        justify-content: space-between;
        border-bottom: 1px solid var(--colorMidGrey);
        margin-bottom: 16px;
      }

      .notifyheading {
        font-weight: 600;
        line-height: 24px;
        color: #000;
      }
      .clear_close {
        display: flex;
        align-items: center;
        gap: 10px;
      }
      .clear_all {
        color: var(--colorBackgroundHeaderBlue);
        font-weight: 400;
        line-height: 16px;
        line-break: normal;
        color: #722bcf;
      }
      .notification {
        display: flex;
        flex-direction: column;
        color: #000;
      }

      .notification_content:hover,
      .notification_content:active {
        background-color: var(--colorLighterGrey);
        font-weight: bolder;
      }
      .notification_overview {
        height: 60vh;
        overflow-y: auto;
      }

      .notification_content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
        margin-bottom: 40px;
        padding: 16px 8px;
      }

      .notification_title {
        display: inline-block;
        border: none;
        margin-bottom: 8px;
        font-size: 14px;
        font-weight: bold;
      }
      .notifyAlert {
        display: flex;
        min-width: 344px;
        justify-content: space-between;
        align-items: flex-start;
        padding-right: 10px;
      }
      .notification_time {
        margin-bottom: 8px;
        margin-top: 8px;
      }
      .seeMore {
        border: none;
        background: none;
        font-size: 14px;
        line-height: 16px;
        text-align: left;
        font-weight: 600;
        cursor: pointer;
        padding: 0;
        color: var(--colorBlue);
        outline: none;
      }

      .notificationContentDot {
        border-radius: 50%;
        width: 8px;
        height: 8px;
        background-color: #f08829;
        margin-right: 20px;
      }
    }
  }
  // end of bell notification area
  .profileNameBox {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid #b9cff9;
    position: relative;
    margin: 0 10px;

    .profileNameInnerBox {
      border-radius: 50%;
      border: 2px solid #0e347e;
      position: absolute;
      top: 0px;
      left: 0px;
      width: 36px;
      height: 36px;

      .profileNameInnerBoxWithContent {
        background-color: var(--colorWhite);
        position: absolute;
        border-radius: 50%;
        top: 0px;
        left: 0px;
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #0d0f11;
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
      }
    }
  }

  .verticalBar {
    height: 50%;
    width: 1px;
    position: relative;
    background-color: var(--colorWhite);
    margin: 0 10px;

    @media only screen and (max-width: 992px) {
      display: none;
    }
  }

  .dropdown {
    .dropdownButtonArea {
      padding: 10px;
    }
    .dropdownMenu {
      text-align: left;
      padding: 12px;
      border: 1px solid #d7dce0;
      background-color: var(--colorWhite);
      border-radius: 4px;
      color: #24292e;
      min-width: 200px;
      margin-top: 28px;

      .profileName {
        font-weight: 600;
        font-size: 16px;
        text-transform: capitalize;
      }
      .profileEmail {
        font-weight: 400;
        font-size: 14px;
      }

      .navLink {
        display: block;
        padding-bottom: 10px;
        color: inherit;
        font-size: 16px;
        letter-spacing: 0.4px;
        cursor: pointer;

        &:hover {
          font-weight: bold;
        }

        &.logoutLink {
          margin-bottom: 0 !important;
        }
      }
    }
  }
}
