.stepperBox {
  display: flex;
  justify-content: space-between;
  min-width: 100%;
  position: relative;
  margin-bottom: 1.5rem;

  .lineSeparatorBackground {
    position: absolute;
    height: 1px;
    top: 45%;
    width: 100%;
    background: var(--colorDimGreen);
  }

  .stepperArea {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 4px;
    background-color: var(--colorWhite);
    z-index: 1;

    &.firstElement {
      transform: translateX(20px);
    }
    &.lastElement {
      transform: translateX(-20px);
    }
  }

  .label {
    height: 26px;
    width: 26px;
    border: 1px solid #81909d;
    color: 1px solid #81909d;
    background-color: var(--colorWhite);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    line-height: 14px;
    border-radius: 50%;
    font-weight: bold;

    &.current {
      color: var(--colorDimGreen);
      border: 1px solid var(--colorDimGreen);
    }

    &.active {
      background-color: var(--colorDimGreen);
      color: var(--colorWhite);
      border: 1px solid var(--colorDimGreen);
    }
  }
}
