.continueButton {
  background-color: #713fff;
  border: 1px solid #713fff;

  margin-top: 40px;
  outline: none;
  border-radius: 4px;
  color: #ffffff;
  padding: 12px 24px;
}
