.kycCard {
  display: flex;
  flex-direction: column;
  padding: 1.25rem;
  align-items: center;
  border-radius: 6px;
  border: 1px solid #d7dce0;

  .top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 0.5rem;
    .infoText {
      font-size: 14px;
      line-height: 150%;
      color: #505d67;
    }

    .iconArea {
      background-color: var(--colorHoverState);
      min-height: 40px;
      min-width: 40px;
      max-height: 40px;
      max-width: 40px;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.2rem;
      margin-right: 2rem;
      color: var(--colorBackgroundBlue);
      margin-bottom: 1rem;
    }
  }

  &:hover {
    cursor: pointer;
    transform: scale(1.01);
    background-color: var(--colorBlue);
    color: var(--colorWhite);

    .iconArea {
      background-color: var(--colorMidShadePurple);
    }
    .infoText {
      color: var(--colorWhite);
    }
  }

  .right {
    font-size: 1rem;
  }
}
