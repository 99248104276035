.pagenotfound {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 10% 25%;
  text-align: center;
}

.pagenotfound > div > h1 {
  font-weight: 600;
  color: #051A52;
  font-size: 32px;
  margin-bottom: 0;
}

.pagenotfound > div > p {
  margin-top: 1rem;
  color: #041644;
  font-size: 20px;
  margin-bottom: 1.5rem;
}

.pagenotfound > button {
  height: 48px;
  width: 208px;
  font-size: 1rem;
  font-weight: 600;
}