@mixin hoverState {
  background-color: var(--colorMidPurple);

  .title {
    color: #ffffff;
  }
  .subTitle {
    color: #d7dce0;
  }

  svg {
    fill: #0f42a4;
  }

  .rightArrow {
    svg {
      path {
        stroke: white;
      }
    }
  }
}
.tabsAlignment {
  // display: grid;
  // grid-template-columns: repeat(2, 1fr);
  // grid-gap: 20px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .tabItem {
    display: flex;
    align-items: flex-start;
    background-color: var(--colorWhite);
    border: 1px solid #713fff;
    border-radius: 16px;
    padding: 12px;
    cursor: pointer;

    &.active,
    &:hover {
      @include hoverState();
    }
    &:hover {
      border: 1px solid var(--colorMidBlue);
    }

    .corporateIcon {
      align-self: center;
      svg {
        fill: inherit;
        margin: 0;
        height: 40px;
        width: 40px;
        background-color: #f4f5f6;
        padding: 5px;
        border-radius: 50%;
      }
    }

    .investorTypeText {
      padding-left: 18px;
    }
    .rightArrow {
      align-self: center;
      margin-left: auto;
      svg {
        path {
          stroke: #bdbdbd;
        }
      }
    }

    .title {
      line-height: 24px;
      font-size: 20px;
      font-weight: bold;
      font-family: var(--fontBold);
      margin-bottom: 0px;
      padding-top: 10px;
      padding-bottom: 10px;
      color: #0d0f11;
    }
    .subTitle {
      line-height: 20px;
      font-size: 14px;
      margin-bottom: 0px;
      color: #3a434b;
    }
  }
}
