$sidebar-horizontal-padding: 20px;

@mixin hoverState {
  background-color: #e3d9ff;
  border-left: 5px solid var(--colorMidBlue);
  color: var(--colorMidBlue);

  @media only screen and (max-width: 992px) {
    border-left: unset;
  }

  i {
    fill: var(--colorMidBlue);
    transform: translateX(-5px); // Make the icons be on thesame line
  }
}

.sidebarElements {
  display: block;
  list-style: none;
  padding-left: 0;
  min-width: 100%;
  max-width: 100%;
  margin-top: 90px;
  position: relative;

  .sidebarLine {
    height: 1px;
    position: relative;
    background-color: #f2f5f9;
    margin: 8px $sidebar-horizontal-padding;

    &.fullWidth {
      margin-left: 0;
      margin-right: 0;
    }

    @media only screen and (max-width: 992px) {
      display: none;
      margin: 8px 0;
    }
  }

  .navLink {
    font-size: 15px;
    color: #3a434b;
    text-align: left;
    padding: 12px $sidebar-horizontal-padding;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    &.selected,
    &:hover {
      @include hoverState();
    }

    i {
      fill: inherit;
      margin: 0;
      font-size: 1rem;
    }

    @media only screen and (max-width: 992px) {
      color: var(--colorWhite);
      text-align: center;
      justify-content: center;
      font-size: 20px;
      padding: 12px 0;

      i {
        display: none;
      }
    }

    span {
      margin-left: 12px;
    }

    a:hover {
      text-decoration: none;
    }
  }
}
