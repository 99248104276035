.termlist {
  margin-left: 0;
  padding-left: 24px;
}
.termlist li {
  padding-bottom: 24px;
  padding-left: 16px;
}
.checkagreement {
  margin-bottom: 24px;
}
.checkagreement label {
  margin-left: 19px;
  font-size: 18px;
}
.checkagreement input {
  width: 18px;
  height: 18px;
}
input[type="checkbox"] {
  accent-color: var(--colorHoverState);
}
