.box {
  border: 1px solid #d7dce0;
  box-sizing: border-box;
  border-radius: 4px;
  min-height: 120px;
  max-height: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  color: #3a434b;

  &.disabled {
    opacity: 0.75;
    cursor: not-allowed;
    pointer-events: none;
  }

  .top {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: 24px;
    letter-spacing: 0.25px;
    height: 75%;
    text-align: center;

    .month {
      font-weight: 600;
      font-size: 24px;
    }
    .label {
      font-size: 16px;
      text-transform: capitalize;
    }
  }
  .bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25%;
    color: #0d0f11;
    font-size: 14px;
    line-height: 16px;
    background-color: #d7dce0;
    font-weight: 600;
    width: 100%;
    vertical-align: middle;
  }

  &.active {
    // background-color: #e8effd;
    background-color: var(--colorHoverState);
    // color: #0f42a4;
    color: var(--colorBackgroundPurple);
    border: 1px solid #e8effd;

    .bottom {
      color: var(--colorWhite);
      background-color: var(--colorBlue);
    }
  }
}
