.top {
  border: 1px solid #d7dce0;
  border-radius: 4px;

  img {
    height: 150px;
    min-width: 100%;
    max-width: 100%;
  }

  .details {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    padding: 16px 8px;

    @media only screen and (max-width: 1024px) {
      justify-content: flex-start;
    }

    .item {
      width: 20%;
      max-width: 20%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      @media only screen and (max-width: 1024px) {
        width: 33%;
        max-width: 33%;
        margin: 10px 0px;
      }

      @media only screen and (max-width: 992px) {
        width: 50%;
        max-width: 50%;
        margin: 10px 0px;
      }

      .itemTop {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.4px;
        color: #3a434b;
      }

      .itemBottom {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0.25px;
        color: var(--colorBackgroundBlue);
      }
    }
  }
}

.bottom {
  .titleRightAlign {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .toggleTab {
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.4px;
      padding: 4px 12px;
      background: #f4f5f6;
      border-radius: 12px;
      margin-right: 8px;
      cursor: pointer;

      &.activeTab {
        color: var(--colorMidBlue);
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  //   Delete This when chart is implemented
  img {
    min-width: 100%;
    max-width: 100%;
  }
}
