.card {
  background: var(--colorWhite);
  border: 1px solid #d7dce0;
  border-radius: 4px;
  border-bottom: 4px solid #200865;
  overflow: hidden;

  .value {
    font-weight: bold;
    font-family: var(--fontBold);
    font-size: 22px;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: #3a434b;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
    margin-top: 15px;
  }

  .title {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    margin-bottom: 8px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #0d0f11;
    text-transform: capitalize;
    display: flex;
    align-items: center;
  }
}
