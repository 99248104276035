// NEW FONTS
@font-face {
  font-family: font-regular;
  src: url("./assets/fonts/DM/DM_Sans/DMSans-Regular.ttf");
}
@font-face {
  font-family: font-medium;
  src: url("./assets/fonts/DM/DM_Sans/DMSans-Medium.ttf");
}

@font-face {
  font-family: font-semi-bold;
  src: url("./assets/fonts/DM/DM_Sans/DMSans-Bold.ttf");
}

// OLD BRANDING  COLORS
// :root {
//   --colorBlue: #0064b8;
//   --colorLightBlue: #93a5ff;
//   --colorMidBlue: #2c6eec;
//   --colorDarkBlue: #041644;
//   --colorBackgroundBlue: #0e347e;
//   --colorWhite: #ffffff;
//   --colorBlack: #000000;
//   --colorPageBlack: #3a434b;
//   --colorDimGrey: #707070;
//   --colorLightBlack: #272525;
//   --colorLighterGrey: #f4f5f6;
//   --colorLightGrey: #8d8585;
//   --colorGrey: #9da9b3;
//   --colorLightGreen: #00a006;
//   --colorDimGreen: #27be63;
//   --colorYellow: #ec9523;
//   --colorOrange: #f08829;
//   --colorRed: #e43535;
//   --colorDimRed: #ca1b1b;
//   --borderRadius: 12px;
//   --borderRadiusSm: 6px;
//   --borderRadiusMd: 9px;
//   --borderRadiusLg: 15px;
//   --fontRegular: font-regular;
//   --fontSemiBold: font-semi-bold;
//   --fontBold: font-medium;
//   --fontMedium: font-medium;
//   --headerHeight: 65px;
//   --breakpoint-xs: 0;
//   --breakpoint-sm: 576px;
//   --breakpoint-md: 768px;
//   --breakpoint-lg: 992px;
//   --breakpoint-xl: 1200px;

// }
// NEW BRAND UI UPDATE FOR ADVEST
:root {
  --colorBlue: #261555;
  --colorLightBlue: #93a5ff;
  --colorMidBlue: #713fff;
  --colorDarkBlue: #041644;
  --colorBackgroundBlue: #200865;
  --colorWhite: #ffffff;
  --colorBlack: #000000;
  --colorPageBlack: #3a434b;
  --colorDimGrey: #707070;
  --colorLightBlack: #272525;
  --colorLighterGrey: #f4f5f6;
  --colorLightGrey: #8d8585;
  --colorGrey: #9da9b3;
  --colorLightGreen: #00a006;
  --colorDimGreen: #27be63;
  --colorYellow: #ec9523;
  --colorOrange: #f08829;
  --colorRed: #e43535;
  --colorDimRed: #ca1b1b;
  --borderRadius: 12px;
  --borderRadiusSm: 6px;
  --borderRadiusMd: 9px;
  --borderRadiusLg: 15px;
  --fontRegular: font-regular;
  --fontSemiBold: font-semi-bold;
  --fontBold: font-medium;
  --fontMedium: font-medium;
  --headerHeight: 65px;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;

  // New UI REVAMP
  --colorPurple: #261555;
  --colorBackgroundPurple: #200865;
  --colorMidPurple: #713fff;
  --colorMidShadePurple: #a07fff;
  --colorHoverState: #e3d9ff;
  --colorSidebarBackground: #f2f5f9;
  --colorGreyPurple: #645e77;
}

body {
  line-height: 1.5;
  background-color: var(--colorWhite);
  color: var(--colorPageBlack);
  font-family: var(--fontRegular) !important;
  overflow-x: hidden;
  scroll-behavior: smooth;
  font-family: "DM Sans", sans-serif;
}

::-webkit-scrollbar-track {
  border: 1px solid var(--colorBlue);
  border-radius: 5px;
  background-color: var(--colorWhite);
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--colorBlue);
}

/* Chrome, Safari, Edge, Opera */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  display: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.crimson {
  color: crimson;
}

a {
  &:hover {
    text-decoration: none;
  }
}

@mixin advancly-btn {
  border-radius: 4px;
  padding: 12px 24px;
  color: var(--colorWhite);

  &:hover {
    color: var(--colorWhite);
    font-weight: bold;
  }

  &:focus {
    outline: unset;
    box-shadow: unset;
  }
}

.advancly-btn {
  @include advancly-btn();
  background-color: var(--colorMidBlue);

  &:disabled {
    background-color: #645e77;
  }
}

.btn-sm {
  padding: 8px 16px !important;
}

.advancly-white-btn {
  @include advancly-btn();
  background-color: var(--colorWhite);
  border-color: var(--colorMidBlue);
  color: var(--colorMidBlue);

  &:hover {
    border-color: var(--colorMidBlue);
    color: var(--colorMidBlue);
    font-weight: bold;
  }
}

.advancly-nobg-btn {
  @include advancly-btn();
  background-color: var(--colorWhite);
  border-color: var(--colorWhite);
  color: #3a434b;
  font-weight: bold;

  &:hover {
    border-color: var(--colorWhite);
    color: #3a434b;
  }
}

.advancly-off-btn {
  @include advancly-btn();
  background-color: #e8effd;
  border-color: #e8effd;
  color: var(--colorMidBlue);

  &:hover {
    border-color: #e8effd;
    color: var(--colorMidBlue);
    font-weight: bold;
  }
}

.advancly-grey-btn {
  @include advancly-btn();
  background-color: #645e77;
  border-color: #645e77;
  color: var(--colorWhite);

  &:hover {
    border-color: #645e77;
    color: var(--colorWhite);
    font-weight: bold;
  }
}

.advancly-red-btn {
  @include advancly-btn();
  background-color: #fce9e9;
  border-color: #fce9e9;
  color: var(--colorDimRed);

  &:hover {
    border-color: #fce9e9;
    color: var(--colorDimRed);
    font-weight: bold;
  }
}

.advancly-dimred-btn {
  @include advancly-btn();
  background-color: #e43535;
  border-color: #e43535;
  color: var(--colorWhite);

  &:hover {
    border-color: #e43535;
    color: var(--colorWhite);
    font-weight: bold;
  }
}

.text-underline {
  text-decoration: underline;
}
.text-underline-hover {
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.color-orange {
  color: var(--colorOrange);
}
.color-lighter-grey {
  color: var(--colorLighterGrey);
}
.color-blue {
  color: var(--colorMidBlue);
}
.hover-class {
  &:hover {
    color: var(--colorMidShadePurple);
  }
}
.color-dark-blue {
  color: var(--colorDarkBlue);
}
.color-bg-blue {
  color: var(--colorBackgroundBlue);
}
.color-red {
  color: var(--colorRed);
}
.color-page-black {
  color: var(--colorPageBlack);
}
.color-grey {
  color: var(--colorGrey);
}
.bg-light-grey {
  color: var(--colorLightGrey);
}
.color-green {
  color: var(--colorDimGreen);
}
.color-mid-purple {
  color: var(--colorMidPurple);
}
.color-background-purple {
  color: var(--colorBackgroundPurple);
}
.bg-lighter-grey {
  background-color: var(--colorLighterGrey);
}
.title-align {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  flex-wrap: wrap;

  @media (max-width: 576px) {
    > div {
      margin-bottom: 10px;
    }
  }
}
.subtitle-align {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;

  @media (max-width: 576px) {
    > div {
      margin-bottom: 10px;
    }
  }
}
.page-title {
  font-weight: 600;
  font-family: var(--fontBold);
  color: var(--colorBackgroundBlue);
  font-size: clamp(20px, 2.5vw, 28px);
  line-height: 32px;
}
.page-subtitle {
  font-weight: 600;
  font-family: var(--fontBold);
  font-size: clamp(16px, 2.5vw, 18px);
  line-height: 24px;
  letter-spacing: 0.25px;
  color: #0d0f11;
}

.form-label {
  font-size: 14px;
  line-height: 16px;
  font-style: normal;
  font-weight: normal;
  // color: var(--colorDarkBlue);
}

.font-weight-bold {
  font-family: var(--fontBold);
}

.cursor-pointer {
  cursor: pointer;
}
.cursor-disabled {
  cursor: not-allowed;
  pointer-events: none;
}

.input-group,
.form-group {
  .form-control {
    &:focus {
      box-shadow: 0px 7px 16px rgba(171, 180, 189, 0.23);
      border: 1px solid var(--colorMidBlue);
    }
  }
  .input-group-append {
    .input-group-text {
      background-color: unset;
      color: var(--colorMidBlue);
      font-size: 14px;
      line-height: 16px;

      &:hover {
        font-weight: bold;
        font-family: var(--fontBold);
      }
    }
  }
}

.centralize-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 80vh;
  margin-left: auto;
  margin-right: auto;
  width: 35%;

  @media (max-width: 991px) {
    width: 60%;
  }
  @media (max-width: 768px) {
    width: 75%;
  }
  @media (max-width: 576px) {
    width: 90%;
  }
}

.no-advancly-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
  width: 100%;
  padding: 50px;
  color: var(--colorGrey);

  i {
    font-size: 3rem;
  }

  span {
    font-size: 16px;
  }
}

.alignToggleTabItems {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-bottom: 1px solid #d7dce0;
  margin-bottom: 1.25rem;
  overflow-x: auto;
  overflow-y: hidden;
  flex-wrap: wrap;

  .tabItem {
    margin: 10px 2rem 0px 0px;
    text-transform: capitalize;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: #0d0f11;
    cursor: pointer;
    text-align: center;

    &.activeTab {
      font-weight: 500;
      color: var(--colorBackgroundBlue);
      font-family: var(--fontBold);

      &::after {
        content: " ";
        width: 100%;
        display: inline-block;
        height: 4px;
        background-color: var(--colorMidBlue);
        position: relative;
        top: 0.1rem;
      }
    }
  }

  &.equalHalf {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    .tabItem {
      margin-right: 0px;
    }
  }
}

.more-icon {
  cursor: pointer;
  &::before {
    content: url(./assets/img/more-icon.svg);
  }
  &:hover {
    transform: scale(1.1);
  }
}

.border-bottom-1 {
  border-bottom: 1px solid #d7dce0;
}

.alert {
  color: #0d0f11;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  &.alert-dismissible .close {
    bottom: 0px !important;

    &:focus {
      outline: unset;
    }
  }
}

.modal {
  .close {
    &:focus {
      outline: unset;
    }
  }
}
.investment-map-section {
  padding: 4rem;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--colorLighterGrey);

  &.tall {
    padding: 8rem;
  }

  i {
    font-size: 3rem;
  }
}
.card {
  border: 1px solid #d7dce0;
  border-radius: 4px;
  padding: 16px;
  margin-bottom: 12px;
  display: flex;
  & a:hover {
    color: var(--colorBackgroundBlue);
  }
}

.currency-badges {
  flex-direction: row;
  display: flex;
  > div {
    height: 32px;
    width: 32px;
    font-size: 0.7rem;
    font-weight: bold;
    border-radius: 50%;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    color: var(--colorWhite);
    background-color: var(--colorBlue);
    transform: translateX(-0.35rem);


    &:first-child {
      background-color: var(--colorLightBlue);
      transform: translateX(0);
    }
  }
}

.badge {
  color: var(--colorPageBlack);
  padding: 8px;
}

.two-grid-layout {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;

  @media only screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
}

.three-grid-layout {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;

  @media only screen and (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media only screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
}

.four-grid-layout {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 25px;

  @media only screen and (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and (max-width: 576px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

@mixin tags {
  padding: 4px 8px;
  border-radius: 0.25rem;
  text-transform: capitalize;
}

@mixin successTag {
  @include tags();
  background-color: #eafbf1;
  color: #1e944d;
}
@mixin pendingTag {
  @include tags();
  color: #d66f0f;

  background-color: #fdf2e7;
}
@mixin errorTag {
  @include tags();
  color: var(--colorDimRed);
  background-color: #fce9e9;
}

.custom-advancly-tag {
  &.success {
    @include successTag();
  }
  &.pending {
    @include pendingTag();
  }
  &.error {
    @include errorTag();
  }
}

.dropdown-button {
  padding: 6px 12px;
  display: block;
  cursor: pointer;
  font-size: 14px;

  &:hover {
    background-color: var(--colorLighterGrey);
    font-weight: bold;
  }
}

.align-unique-page {
  width: 70%;

  @media (max-width: 991px) {
    width: 100%;
  }

  &.privacy-terms-page {
    text-align: justify;
    p,
    li {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.048px;
      color: #3a434b;
    }

    li {
      margin-bottom: 10px !important;
    }
  }
}

.pincode-input-container {
  min-width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;

  .pincode-input-text {
    border: 0px !important;
    border-bottom: 2px solid #bdbdbd !important;
    height: "50px";
    width: "50px";

    &:focus {
      box-shadow: "0px 7px 16px rgba(171, 180, 189, 0.23)";
      border: "1px solid #713FFF";
    }
  }
}

.alignStepOneBoxes {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: clamp(20px, 2.5vw, 40px);
  margin-bottom: 1.5rem;

  .box {
    border: 1px solid #d7dce0;
    box-sizing: border-box;
    border-radius: 4px;
    min-height: 175px;
    max-height: 175px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    // color: #677684;
    color: var(--colorGreyPurple);
    padding: 1rem;
    position: relative;

    .currency {
      font-weight: 600;
      font-size: 36px;
      line-height: 40px;
      letter-spacing: 0.25px;
      margin-bottom: 10px;
    }
    .label {
      font-size: 16px;
      line-height: 16px;
      letter-spacing: -0.05px;
      color: #3a434b;
    }

    &.active {
      background-color: var(--colorHoverState);
      // color: #0f42a4;
      color: var(--colorBackgroundPurple);
      border: 1px solid #e8effd;

      .label {
        // color: #0f42a4;
        color: var(--colorBackgroundPurple);
      }
    }

    &.disabled {
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.8;

      &::after {
        display: inline-block;
        content: "Sold Out";
        background-color: var(--colorDimRed);
        color: var(--colorWhite);
        position: absolute;
        top: 0;
        left: 0;
        padding: 2px 8px;
        border-radius: 4px;
        transform: translate(-12px, 8px) rotate(-45deg);
        font-size: 0.7rem;
        text-transform: capitalize;
      }
    }
  }
}

.powerbi-embed-container {
  height: 500px;
  width: 100%;
  padding: 0 !important;
  margin: 0 !important;
}

.input-group-prepend {
  .input-group-text {
    background-color: unset;
    border-right-width: 0;

    select {
      border: 0;

      &:focus-visible {
        outline: 0;
      }
    }
  }
}
