.sectorsGridCard {
  background: var(--colorWhite);
  border: 1px solid #d7dce0;
  border-radius: 4px;

  img {
    height: 96px;
    max-width: 100%;
  }

  .sectorsGridCardBottom {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 12px;

    .sectorsCardInterestRate {
      font-weight: normal;
      font-style: normal;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.4px;
      color: #3a434b;
    }

    .sectorsCardTitle {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 5px;
      letter-spacing: 0.25px;
      color: var(--colorBackgroundBlue);
      text-transform: capitalize;
    }
  }
}
