.pickerArea {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .pickerCardArea {
    width: 340px;
    max-width: 60vw;
    min-height: 300px;
  }

  .pickerCard {
    height: 100%;
    width: 100%;
    border-radius: 6px;
    border: 1px dashed #5b8ef0;
    background-color: #e8effd;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #1354d3;
    cursor: pointer;
    padding: 3rem 1.5rem;

    i {
      font-size: 40px;
    }
  }
}
