.contactAlign {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;
  margin: auto;

  @media (max-width: 991px) {
    width: 80%;
  }
}

.contactLanding {
  width: 82.5%;
  margin-top: 2rem;

  @media (max-width: 576px) {
    width: 100%;
  }
}

.contactCard {
  display: flex;
  padding: 2rem;
  justify-content: space-between;
  align-items: center;
  border-radius: 6px;
  box-shadow: 0px 8px 16px rgba(171, 180, 189, 0.23);

  .left {
    width: 90%;
    display: flex;
    align-items: center;

    .infoText {
      font-size: 14px;
      line-height: 150%;
      color: #505d67;
    }

    .iconArea {
      // background-color: var(--colorLighterGrey);
      background-color: var(--colorHoverState);
      min-height: 40px;
      min-width: 40px;
      max-height: 40px;
      max-width: 40px;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1rem;
      margin-right: 1.5rem;
      color: var(--colorBackgroundPurple);
    }
  }

  &:hover {
    cursor: pointer;
    transform: scale(1.01);
    background-color: var(--colorBlue);
    color: var(--colorWhite);

    .iconArea {
      background-color: var(--colorMidShadePurple);
    }
    .infoText {
      color: var(--colorWhite);
    }
  }

  .right {
    font-size: 1.5rem;
  }
}
