.sectorDetailsGridRight {
  border: 1px solid #d7dce0;
  border-radius: 4px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  svg {
    margin-bottom: 10px;
  }

  .title {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.002em;
    color: #0d0f11;
    margin-bottom: 10px;
  }
  .subTitle {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #3a434b;
    margin-bottom: 10px;
  }
}
