.box {
  border: 1px solid #d7dce0;
  border-radius: 4px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #3a434b;
  padding: 1rem;
  margin-bottom: 1rem;

  &.active {
    background-color: var(--colorHoverState);
    // color: #0f42a4;
    color: var(--colorBackgroundPurple);
    border: 1px solid #e8effd;
    font-weight: bold;
  }
}
