.alignStepTwoBoxes {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: clamp(10px, 2.5vw, 20px);

  @media (max-width: 568px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 40px;
  }
}
input[type="radio"] {
  accent-color: var(--colorBackgroundPurple);
}
