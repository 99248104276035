.detailsHeading {
  display: inline-block;
  padding-top: 100px;
  h2 {
    font-weight: 700;
    color: black;
  }
  p {
    color: #3a434b;
  }
}
