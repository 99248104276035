.detailsGrid {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 30px;
  margin-top: 20px;

  @media only screen and (max-width: 992px) {
    grid-template-columns: 1fr;
  }

  .fancyPoint {
    :nth-child(1) {
      &::before {
        height: 8px;
        width: 8px;
        background-color: #a07fff;
        margin-bottom: 2px;
        content: " ";
        display: inline-block;
        margin-right: 0.5rem;
      }
    }
    > div:nth-child(2) {
      padding-left: calc(0.5rem + 8px);
    }
  }
}
